import styled from 'styled-components';
import {bp, Z, HEADER_HEIGHT, SIDEBAR_WIDTH} from 'core/styles';

export let SidebarContainer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background-color: #fafafa;
    border-right: 1px solid #ccc;

    ${bp('md')} {
        z-index: ${Z.sidebar};
        width: ${SIDEBAR_WIDTH};
    }
`;

export let ContentContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${bp('md')} {
        left: ${SIDEBAR_WIDTH};
    }
`;

export let Inner = styled.div`
    background-color: #fff;
    position: absolute;
    top: ${HEADER_HEIGHT};
    right: 0;
    bottom: 0;
    left: 0;
`;
